import React from "react";

// export default function ({link, setLink}: any) {
//   if (!link) return <></>;
//   return (
//     <>
//       <div className='modal-background' onClick={()=> setLink(null)}/>
//       <div style={{padding: "40px"}}>
//         <div id='videoPlayerModal'>
//         </div>
//       </div>
//     </>
//   )
// }


export default function ({link, setLink}: any) {
  if (!link) return <></>;
  return (
    <>
      <div className='modal-background' onClick={()=> setLink(null)}/>
      <div id="myModal" className="modalCurrent">
        <div className="modal-contentCurrent">
          <span onClick={()=> setLink(null)} className="close">&times;</span>
          <iframe 
            style={{width: "100%", height: "100%", minHeight: "500px"}}
            id="player"
            src={`https://www.youtube.com/embed/${link}/?enablejsapi=1&origin=https://seobuster.ru`}
            frameBorder="0"
          />
        </div>
      </div>
    </>
  )
}