import React from "react"

function Policy () {
    return <div style={{
        margin: 40
    }}>
        <h1>Политика оплаты, доставки и возврата</h1>
        <h3>Оплата. Особенности оплаты</h3>

        <p>
            Оплата товара производится любым из представленных на сайте методов.
            Оплата проводится онлайн.
            В случае возникновения вопросов, связанных с оплатой товара,
            необходимо обращаться по электронной почте info@seobuster.ru
        </p>

        <h3>Доставка. Правила доставки</h3>

        <p>
            Доставка товаров отсутствует, фактически оплачивается право доступа для аккаунта Пользователя к системе.
            Активирование аккаунта производится автоматически,
            при получении информации об оплате товара от системы приема платежей или же при получении
            оплаты по безналичному платежу на наш расчетный счет. При этом отправка уведомлений от сервиса остается
            не обязательной, но возможной.
        </p>

        <h3>Возврат</h3>

        <p>
            В случае, если пользователь считает качество работы Сервиса неудовлетворительным, он может аннулировать свой аккаунт.
            Если аннулирование произошло в течение двух суток с момента оплаты подписки и при условии получения не более чем 30 отчетов
            в системе - мы гарантируем полный возврат денежных средств.
            Для аннулирования аккаунта необходимо отправить письмо на адрес info@seobuster.ru с указанием своего логина и описанием ситуации.
        </p>

        <p>
            Возврат средств возможен только на те же реквизиты, с которых средства были перечислены в качестве оплаты.
        </p>

        <p>
            Удостоверьтесь, что вы ознакомились с <a href='https://seobuster.ru/pc-oferta'>Пользовательским соглашением</a>.
        </p>
    </div>
}

export default Policy