import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react"
import UserImg from '../img/user.jpg'
import {IUser, Nullable} from "../utils.types";
import {Api} from "../api";
import {ApiException, Union, PaymentResponse} from "../utils.types";
import {useNavigate} from "react-router-dom";

interface ITopupProps {
    user: IUser | null,
    setUser: Dispatch<SetStateAction<Nullable<IUser>>>
}

function Topup({user, setUser}: ITopupProps) {
    const navigate = useNavigate()

    const [amount, setAmount] = useState(0);

    const requestPayment = async () => {
        const res: Union<PaymentResponse, ApiException> = await Api.CreatePayment((parseInt(String(amount * 100)))); // копейки
        if ("error" in res && res.error) {
            // todo
            alert('оплата не прошла')
            console.warn(res.error)
        } else {
            let sucess: PaymentResponse = res as PaymentResponse;
            if (sucess.url) window.location.href = sucess.url;
            else alert('система оплаты не отвечает')
        }
    }

    useEffect(() => {
        if (!user) {
            navigate('/pf')
        }
    }, [])

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title d-inline-block">Пополнить баланс</h4>
            </div>
            <div className="card-block">
                <div className="table-responsive" style={{overflowX: 'hidden'}}>
                    <table className="table mb-0 new-patient-table">
                        <thead>
                        <tr>
                            <th>Логин</th>
                            <th>Сумма</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <td>
                                <img width="28" height="28" className="rounded-circle" src={UserImg} alt=""/>
                                <h2>{user!.login}</h2>
                            </td>
                            <td>
                                <input value={amount} type="text" onChange={(e) => setAmount(Number(e.target.value))}/>
                            </td>
                            <td>
                                <button onClick={() => requestPayment()}>Пополнить</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Topup;
