import React, {useEffect, useState} from "react";
import {formatNumber, IPagination, IProject, ITask, TaskEnum} from "../utils.types";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Api} from "../api";
import { RenderStatus } from './RenderStatus'

function PovedFactorAdmin() {
    const [data, setData] = useState<IPagination>({
        count: 0,
        tasks: [],
        projects: []
    })

    const [searchParams, setSearchParams] = useSearchParams()

    const page = Number(searchParams.get('page') || 0)

    const navigate = useNavigate()
    const [query, setQuery] = useState<string>("");

    const fetchData = async (_page: number | null = null) => {
        const data = await Api.GetTasksPagination(_page ? _page : page, query)

        if(!("error" in data)) {
            setData(data)
        }
    }

    useEffect(() => {
        fetchData()
    }, [page])

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter'){
            fetchData(0);
        }
    }

    const handleRowClick = (e: any, id: any) => {
        if (e.target.id == "user") {
            e.preventDefault();
            e.stopPropagation();
        } else {
            navigate(`/pf/${id}/view`, {replace: true})
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Поведенческие Факторы</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-3">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-8">
                                <input style={{marginLeft: "none"}} onKeyPress={handleKeyPress} value={query} onChange={e => setQuery(e.target.value)} type="text" id="search" className="form-control " />
                            </div>
                            <div className="col-4">
                                <button className="btn btn-primary" onClick={()=> fetchData(0)}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX: 'unset'}}>
                        <table id="s" className="table table-striped custom-table tasks">
                            <thead>
                            <tr>
                                <th style={{width: 116, paddingLeft: 10}}>Время</th>
                                <th style={{width: 231}}>Сайт</th>
                                <th style={{width: 90}}>Город</th>
                                <th style={{width: 90}}>Cтраниц</th>
                                <th style={{width: 90}}>Пользователь</th>
                                <th style={{width: 90}}>Запросы</th>
                                <th style={{width: 90}}>Кликов\день</th>
                                <th style={{width: 100}}>Расход мес.</th>
                                <th style={{width: 90}}>Расход\день</th>
                                <th style={{width: 151}}>Задача</th>
                                <th>Статус</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.tasks.map(task => {
                                const project = data.projects[task.projectId] as IProject
                                return <tr key={task._id} className="s"
                                    onClick={
                                        (e) => handleRowClick(e, task._id)
                                    }>
                                    <td style={{paddingLeft: 10}}>
                                        {
                                            new Date(task.updatedAt)!.toLocaleString('ru-RU',
                                                {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    day: '2-digit',
                                                    month: 'short'
                                                })
                                                .split(',')
                                                .reverse()
                                                .join(' ')
                                        }
                                    </td>
                                    <td>{project.site}</td>
                                    <td style={{paddingLeft: 10}}>{project.city}</td>
                                    <td style={{paddingLeft: 10}}>{project.pages}</td>
                                    <td id="user" style={{paddingLeft: 10}}><a href="#" id="user" onClick={(e)=> {e.preventDefault(); navigate(`/user/${project.user._id}/edit`)}}>{project.user.login}</a></td>
                                    <td style={{paddingLeft: 10}}>{project.queries}</td>
                                    <td style={{paddingLeft: 10}}>{project.clicksPerDay}</td>
                                    <td style={{paddingLeft: 10}}>{formatNumber(project.expensePerDay, 0, 0)}</td>
                                    <td>{task?.type === TaskEnum.ChangeFactor
                                        ? (TaskEnum.ChangeFactor + ' на ' + formatNumber(task.factorToChange!, 1, 1))
                                        : (task?.type ?? '')}</td>
                                    <td style={{paddingLeft: 10}}>{project.expensePerMonth}</td>
                                    <td><RenderStatus status={task?.status}/> </td>
                                </tr>
                            }
                            )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div>
                {   (data?.count ?? 0) > 10 &&
                    Array.from({length: Math.ceil(data!.count/30)},
                        (_, i) => <Link style={{fontSize: "large"}} key={i} to={`?page=${i}&query=${query}`}>{' '}{i+1}{' '}</Link>
                    )
                }
            </div>
        </>
    )
}

export default PovedFactorAdmin