import React, {ChangeEvent, FormEvent, useState} from "react";
import {formatNumber, IPriceCalculatedResult, Nullable} from "../utils.types";

function PriceCalculator() {

    const [frequency, setFrequency] = useState<number>(11300)
    const [prettyFrequency, setPrettyFrequency] = useState<string>('11 300')
    const [result, setResult] = useState<Nullable<IPriceCalculatedResult>>(null)

    const makePretty = (val: number): string => {
        return val
            .toLocaleString('ru-RU')
    }

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
            .replaceAll(/[^0-9]/g, '')

        const asInt = Number.parseInt(value) || 0

        const pretty = makePretty(asInt)

        setFrequency(asInt)
        setPrettyFrequency(pretty)
    }

    const onCalculateButtonClick = function (e: FormEvent<HTMLButtonElement>) {
        const clicksPerDay = Math.ceil(frequency / 30 * 0.5)

        const tariff = 1

        const pricePerDay = Math.ceil(tariff * frequency / 30 * 0.5)
        const pricePerMonth = Math.ceil((tariff * frequency / 30 * 0.5) * 30)

        setResult({
            clicksPerDay: clicksPerDay,
            pricePerDay: pricePerDay,
            pricePerMonth: pricePerMonth,
            tariff: tariff
        })
    }

    return (
        <>
            {/*TODO calculator task client 1*/}
            <h4 className="page-title" style={{textAlign: "center", color: 'black', fontWeight: 600, fontSize: 26}}>Калькулятор стоимости</h4>
            <p style={{
                width: '70vw',
                fontSize: 16,
                color: 'black',
                margin: 10
            }}>
                Стоимость формируется из “Доступ” + “расходники”.
                <br/>
                Если сайт вы уже запустили в системе и требуется добавить страницы, то оплатите только за расходники.

            </p>
            <br/>
            <br/>
            <h4 style={{
                color: 'black',
                fontSize: 20,
                marginLeft: 8
            }}>Стоимость доступа</h4>
            <br/>

            <table>
                <thead>
                <tr className="calc-top">
                    {/*<th><div>Настройки и запуск 1 сайта</div></th>
                        <th><div>Стоимость в месяц</div></th>*/}
                    <th>
                        <div>Город</div>
                    </th>
                    <th>
                        <div>1 сайт в месяц</div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr className="calc-down">
                    <td>
                        <div>Москва</div>
                    </td>
                    <td>
                        <div>5 000 руб.</div>
                    </td>
                </tr>
                {/*<tr className="calc-down">
                        <td><div>Санкт-Петербург</div></td>
                        <td><div>7 000 руб.</div></td>
                    </tr>*/}
                <tr className="calc-down">
                    <td>
                        <div>Другие города</div>
                    </td>
                    <td>
                        <div>5 000 руб.</div>
                    </td>
                </tr>
                </tbody>
            </table>
            <br/>
            <br/>
            <br/>

            <h4 style={{
                color: 'black',
                fontSize: 20,
                marginLeft: 8
            }}
            >Расходники</h4>
            <p style={{
                width: '70vw',
                fontSize: 16,
                color: 'black',
                margin: 10
            }}>
                Для расчета стоимости, укажите в поле сумму "Частоты" в кавычках всех запросов по нужному городу и
                нажмите
                кнопку справа.
            </p>
            <p style={{
                width: '70vw',
                fontSize: 16,
                color: 'black',
                margin: 10
            }}>
                Важно: необходимо отправлять полный список запросов по странице, иначе система будет расширять
                самостоятельно, что станет не прогнозируемо для вас по стоимости расходников.
            </p>
            <br/>
            <div className="frequencyWrapper">
                <div className="row">
                    <input className="frequencyInput" type="text" value={prettyFrequency} onChange={onInputChange}/>
                    <button className="calculateButton" onClick={onCalculateButtonClick}>Рассчитать</button>
                </div>
                <div className="row">
                    <i className="frequencyInputLabel">Сумма "частоты" запросов</i>
                </div>
            </div>
            {
                result &&
                <div className="calculateResult">
                    <div className="row">
                        <p>Кликов в день</p>
                        <p>{result.clicksPerDay} кликов</p>
                    </div>


                    <div className="line"/>

                    <div className="row">
                        <p>Стоимость в день</p>
                        <p>{formatNumber(result.pricePerDay, 0, 0)} руб.</p>
                    </div>

                    <div className="line"/>

                    <div className="row">
                        <p>Стоимость в месяц</p>
                        <p>{formatNumber(result.pricePerMonth, 0, 0)} руб.</p>
                    </div>
                </div>
            }
        </>
    )
}

export default PriceCalculator