import {StatusEnum} from "../utils.types";
export const RenderStatus = ({status}: {status: any}) => {
    switch (status) {
        case "Ошибка":
            return (
                <span title="Неверный формат файла">
                    <img src={"/svg/ExclamationMark.svg"}/>
                    Ошибка (наведите)
                </span>
            )

        case "Пауза":
            return (
                <span> 
                    <img src={"/svg/ExclamationMark.svg"}/>
                    Пополните баланс
                </span>
            )
        default:
            return status;
    }
}

export const RenderCustomerStatus = ({status}: {status: any}) => {
    switch (status) {
        case StatusEnum.Error:
            return (
                <span title="Неверный формат файла">
                    <img src={"/svg/ExclamationMark.svg"}/>
                    Ошибка (наведите)
                </span>
            )
        case StatusEnum.Pause:
            return (
                <span> 
                    <img src={"/svg/ExclamationMark.svg"}/>
                    Пополните баланс
                </span>
            )
        case StatusEnum.Done:
            return (
                <span> 
                  Крутится
                </span>
            )
        case StatusEnum.Execute:
            return (
                <span> 
                  Обработка
                </span>
            )
        default:
            return status;
    }
}