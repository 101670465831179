import React, {useEffect, useState} from "react"
import {ApiException, IProject, IUser, Nullable, StatusEnum, TaskEnum, Union, formatNumber} from "../utils.types";
import {Link, useNavigate} from "react-router-dom";
import {Api} from "../api";
import VideoInstructionModal from "./modals/VideoInstructionModal";
import { RenderCustomerStatus } from './RenderStatus'

interface IPovedFactorProps {
    user: IUser
}

function PovedFactor({user}: IPovedFactorProps) {
    const [data, setData] = useState<IProject[]>([])

    const [modalLink, setModalLink] = useState<Nullable<string>>(null)

    const navigate = useNavigate()

    const onClickExpandDescription = () => {
        const arrow = document.querySelector('#descriptionArrow') as HTMLElement
        const text = document.querySelector('.descriptionText') as HTMLElement

        arrow.classList.toggle('expanded')

        text.classList.toggle('no-visible')

        text.focus()

        text.classList.toggle('expandedDescriptionText')
    }

    const onBlur = () => {
        const arrow = document.querySelector('#descriptionArrow') as HTMLElement
        const text = document.querySelector('.descriptionText') as HTMLElement

        arrow.classList.remove('expanded')
        text.classList.add('no-visible')
    }

    const openModal = (id: string) => {
        return () => {
            document.querySelectorAll('.modal-elem')
                .forEach(elem => elem.classList.remove('no-visible'))
            setModalLink(id)
        }
    }

    const closeModal = () => {
        document.querySelectorAll('.modal-elem')
            .forEach(elem => elem.classList.add('no-visible'))

        setModalLink(null)
    }

    const fetchProjects = async () => {
        const projects = await Api.GetMyProjects()

        if (!("error" in projects))
            setData(projects)
    }

    useEffect(() => {
        document.getElementById('closeModal')!.onclick = closeModal
    }, [])

    useEffect(() => {
        fetchProjects()
    }, [])

    return (
        <>
            {modalLink &&
                <VideoInstructionModal link={modalLink} setLink={()=> setModalLink(null)}/>
            }
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Поведенческие Факторы</h4>
                </div>
                <div className="col-9 m-b-20 description">
                    <div className={'description-btn'}>
                        <span id='descriptionArrow'></span> {/* ↓ */}
                        <button className="btn btn-primary" onClick={onClickExpandDescription}>
                            Описание
                        </button>
                    </div>
                    <div /*onBlur={onBlur}*/ className='no-visible descriptionText' id='tooltip' role='tooltip'
                                             onClick={onClickExpandDescription}
                    >
                        <section>
                            <button>закрыть</button>
                            <h5>Как добавить задачу</h5>
                            <>Необходимо нажать кнопку “Добавить сайт”.</>
                            <br/>
                            <>В открывшемся меню, выберите:</>
                            <br/>
                            <ol>
                                <li>
                                    <>режим (кф - коэффициент):</>
                                    <br/>
                                    <ul>
                                        <li>0,1 - если у вас сайт моложе 3 месяцев или у него нет видимости в топ-100
                                        </li>
                                        <li>0,5 - во всех остальных случаях.</li>
                                    </ul>
                                </li>
                                <li>Выберите город из списка</li>
                                <li>
                                    <>Добавьте файл в виде Ексель, внутри которого должны быть следующие столбцы:</>
                                    <br/>
                                    <ol>
                                        <li>адрес страницы</li>
                                        <li>запрос</li>
                                        <li>частота в кавычках по нужному городу (“запрос”)</li>
                                    </ol>
                                </li>
                                <li>Нажмите на кнопку “добавить сайт”.</li>
                            </ol>

                            <>Как добавить запросы или убрать</>
                            <br/>
                            <ul>
                                <li>Нажмите на название сайта. Перейдете в режим настройки.</li>
                                <li>Нажмите на кнопку “плюс” или “минус”, чтобы выбрать файл Эксель. Который состоит из
                                    2-х столбцов (страница, запрос).
                                </li>
                                <li>При удалении, вам нужно указать только то, что нужно убрать (с заполнением 2-х
                                    столбцов в файле).
                                </li>
                            </ul>

                            <p>По всем вопросам пишите в <Link to='/support'>поддержку</Link>.</p>

                            <>Видео-инструкции:</>
                            <br/>
                            <ul>
                                <li><button className="btn btn-primary" onClick={()=> setModalLink('0Rr8X764fYY')}>
                                    Как добавить сайт на сервис
                                    <img className="ml-2" style={{width: "24px", height: "24px"}} src={"/svg/Play.svg"}/>
                                </button></li>
                                <li><button className="btn btn-primary" onClick={()=> setModalLink('S97z_9qnoIE')}>
                                    Как добавить запросы или удалить
                                    <img className="ml-2" style={{width: "24px", height: "24px"}} src={"/svg/Play.svg"}/>
                                </button></li>
                            </ul>
                        </section>
                        <div id="arrow" data-popper-arrow/>
                    </div>
                </div>
                <div className="col-9 m-b-20">
                    <Link to="/pf/add/project" className="btn btn btn-primary btn-rounded">
                        <i className="fa fa-plus"/>
                        {"    "}Добавить Сайт
                    </Link>

                    {
                        !(data.length > 0)
                            ? <div style={{color: 'black', paddingTop: 12}}>
                                {/*//TODO tenek payment*/}
                                <span style={{color: 'black', paddingTop: 12}}>Для запуска задачи </span>
                                <span style={{color: 'dodgerblue', paddingTop: 12, cursor: 'pointer'}}
                                      onClick={() => {
                                          //setPaymentPopup(true)
                                          navigate('/topup')
                                      }}
                                > пополните баланс</span>
                            </div>
                            : []
                    }
                </div>
                {/*{
                    !(data.length > 0)
                        ? <div className="col-9 m-b-20">
                            {user.balance <= 0 && <span>Для запуска задачи <Link to='/topup'>пополните баланс</Link></span>}
                        </div>
                        : []
                }*/}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX: 'unset'}}>
                        <table id="s" className="table table-striped custom-table tasks" /*style={{width: 1040}}*/>
                            <thead>
                            <tr>
                                <th style={{paddingLeft: 10, width: 116}}>Время</th>
                                <th style={{width: 231}}>Сайт</th>
                                <th style={{width: 90}}>Город</th>
                                <th style={{width: 90}}>Cтраниц</th>
                                <th style={{width: 90}}>Запросы</th>
                                <th style={{width: 100}}>Расход мес.</th>
                                <th style={{width: 141}}>Статус</th>
                                <th style={{width: 121}}/>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map(project => {
                                        const redirectToView = () => navigate(`/pf/${project._id}/view`, {
                                            replace: true
                                        })
                                        return (
                                            <tr className="s" key={project._id}>
                                                <td onClick={redirectToView} style={{paddingLeft: 10}}>
                                                    {
                                                        new Date(project.createdAt)!.toLocaleString('ru-RU',
                                                            {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                day: '2-digit',
                                                                month: 'short'
                                                            })
                                                            .split(',')
                                                            .reverse()
                                                            .join(' ')
                                                    }
                                                </td>
                                                <td onClick={redirectToView} style={{paddingLeft: 10}}>
                                                    {project.site}
                                                </td>
                                                <td onClick={redirectToView} style={{paddingLeft: 10}}>
                                                    {project.city}
                                                </td>
                                                <td onClick={redirectToView} style={{paddingLeft: 10}}>
                                                    {project.pages}
                                                </td>
                                                <td onClick={redirectToView} style={{paddingLeft: 10}}>
                                                    {project.queries}
                                                </td>
                                                <td onClick={redirectToView} style={{paddingLeft: 10}}>
                                                    {formatNumber(project.expensePerMonth, 0, 0)}
                                                </td>
                                                <td style={{width: 110}}>
                                                    <RenderCustomerStatus status={project?.lastTask?.status} />
                                                </td>
                                                <td onClick={async () => {
                                                    await Api.DeleteProject(project._id)
                                                    await fetchProjects()
                                                }} className="site-delete">Удалить
                                                    сайт
                                                </td>
                                            </tr>
                                        )
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PovedFactor